<template>
  <div
    :class="[
      'book__cover position-relative',
      { 'book__cover--grey-background': greyBackground },
    ]"
  >
    <BookPrize v-if="prize" :prize="prize" />
    <LazyImage
      v-if="lazy"
      :src="`${cover}&width=${width}&height=${height}&fit=cover`"
      :srcset="`${cover}&width=${width}&height=${height}&fit=cover 1x, ${cover}&width=${
        width * 2
      }&height=${height * 2}&fit=cover 2x`"
      :width="width"
      :height="height"
      :alt="title"
      fluid
    />

    <img
      v-else
      ref="image"
      :src="`${cover}&width=${width}&height=${height}&fit=cover`"
      :srcset="`${cover}&width=${width}&height=${height}&fit=cover 1x, ${cover}&width=${
        width * 2
      }&height=${height * 2}&fit=cover 2x`"
      :width="width"
      :height="height"
      fetchpriority="high"
      class="img-fluid"
      :alt="title"
      :title="title"
      @error="onError"
    />
  </div>
</template>

<script>
import LazyImage from '~/components/LazyImage'
import BookPrize from '~/components/books/BookPrize'
import { getImagePlaceholder } from '~/utils/index'

export default {
  components: {
    LazyImage,
    BookPrize,
  },
  props: {
    cover: {
      type: String,
      required: true,
    },
    isAvailable: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 255,
    },
    height: {
      type: Number,
      default: 392,
    },
    title: {
      type: String,
      default: '',
    },
    greyBackground: {
      type: Boolean,
      default: false,
    },
    prize: {
      type: String,
      default: '',
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onError() {
      const placeholder = getImagePlaceholder()

      if (this.$refs.image) {
        this.$refs.image.src = placeholder
        this.$refs.image.srcset = placeholder
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.book__cover {
  box-shadow: 1px 4px 16px rgba(0, 0, 0, 0.16);
  width: fit-content;
}

.book__cover,
img {
  border-radius: 4px;
}

@include media-breakpoint-up(md) {
  .book__cover,
  img {
    border-radius: 8px;
  }
}

.book__cover--grey-background {
  padding: 7% 23.6%;
  background: radial-gradient(50% 50% at 50% 50%, $white 0%, $brown-50 100%);
  box-shadow: unset;
  border-radius: 0;

  img {
    box-shadow: 1px 4px 16px rgba(0, 0, 0, 0.16);
  }
}
</style>
